export const SchedulerType = {
  SWF: "SWF_FICT",
  PRODUCT_POST: "PRODUCT_POST",
  SHOPPING_EVENT: "SHOPPING_EVENT"
};

export const SchedulerMap = {
  [SchedulerType.SWF]: {
    label: "SWF",
    createUrl: "/swf-offer/new"
  },
  [SchedulerType.PRODUCT_POST]: {
    label: "Post",
    createUrl: "/shop-posts/add-new-shop-post"
  },
  [SchedulerType.SHOPPING_EVENT]: {
    label: "Shopping Event",
    createUrl: "/shopping-events/shopping-events/new"
  }
};

export const SchedulerViewType = {
  CALENDAR: "CALENDAR",
  TABLE: "TABLE"
};
