import { mapGetters } from 'vuex';
import { SchedulerType, SchedulerMap } from '../scheduler.enums';
import router from '@/router';
import WindowRedirectService from '@/core/services/window-redirect.service';

export default {
  props: {
    modal: {
      default: true
    },
    value: Boolean
  },
  name: 'scheduler-create-dialog',
  data() {
    return {
      isOpen: false,
      options: {
        color: 'grey lighten-3',
        width: 300,
        zIndex: 200
      },
      valid: false,
      isSaving: false,
      typeEnums: SchedulerType,
      typeMap: SchedulerMap,
      types: Object.keys(SchedulerMap).filter(item => SchedulerMap[item]?.createUrl),
      form: this.setFormData(),
      requiredRule: [v => !!v || 'Field is required']
    };
  },
  methods: {
    async validateAndSave() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    save() {
      let route;
      const url = this.typeMap[this.form.eventType]?.createUrl;
      route = router.resolve({ path: url, replace: true });

      let redirectedWindow = WindowRedirectService.openWindow(route.href);
      const events = WindowRedirectService.getEvents(redirectedWindow);

      events.onClose(() => {
        redirectedWindow.close();
        redirectedWindow = undefined;
        this.close();
        this.$emit('trigger-reload');
      });
    },
    open() {
      this.isOpen = true;
      this.form = this.setFormData();
    },
    close() {
      this.isOpen = false;
    },
    setFormData() {
      return {
        eventType: SchedulerType.SWF
      };
    }
  },
  computed: {
    ...mapGetters(['currentUserPersonalInfo', 'currentUser'])
  }
};
