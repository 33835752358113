import { mapGetters } from 'vuex';
import { SchedulerType, SchedulerMap } from '../scheduler.enums';
import SchedulerApiService from '@/core/services/api.service.scheduler';
import moment from 'moment';

export default {
  props: {
    modal: {
      default: true
    },
    value: Boolean
  },
  name: 'scheduler-form',
  data() {
    return {
      isOpen: false,
      options: {
        color: 'grey lighten-3',
        width: 500,
        zIndex: 200
      },
      isEdit: true,
      valid: false,
      isSaving: false,
      typeEnums: SchedulerType,
      typeMap: SchedulerMap,
      types: Object.keys(SchedulerMap),
      form: this.setFormData(),
      data: undefined,
      pagination: {
        page: 1,
        itemsPerPage: 100
      },
      requiredRule: [v => !!v || 'Field is required']
    };
  },
  methods: {
    async validateAndSave() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    open(config) {
      this.isOpen = true;
      this.isEdit = !!config.data;
      this.data = config.data;
      this.form = this.setFormData(config.data);
    },
    close() {
      this.isOpen = false;
      this.data = undefined;
    },
    setFormData(data = {}) {
      return {
        eventType: data?.eventType || SchedulerType.SWF,
        eventId: data?.eventId || undefined,
        published: data?.published || undefined,
        startDate: data?.startDate ? moment(data.startDate * 1000).toDate() : moment().toDate()
      };
    },
    save() {
      const postModel = { ...this.form };

      postModel.startDate = moment(postModel.startDate).unix();

      SchedulerApiService.saveSchedule(postModel).then(() => {
        this.close();
        this.$emit('dialog-saved');
        this.$bvToast.toast(this.$i18n.t('common.savedSuccessfully'), {
          title: this.$i18n.t('menu.scheduler'),
          solid: true
        });
      });
    },
    getParams() {
      const params = {};

      params['PageNumber'] = this.pagination.page - 1;
      params['PageSize'] = this.pagination.itemsPerPage;

      return params;
    },
    getItemNames(item) {
      if (!item.shoppingItems?.length) return '';
      return item.shoppingItems.map(item => item.name).join(', ');
    },
    getShopNames(shops) {
      return shops.map(item => item.name).join(',');
    }
  },
  computed: {
    ...mapGetters(['currentUserPersonalInfo', 'currentUser'])
  }
};
