import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import { SchedulerMap, SchedulerViewType } from "../scheduler.enums";
import SchedulerForm from "../scheduler-form/scheduler-form.vue";
import SchedulerCreateDialog from "../scheduler-create-dialog/scheduler-create-dialog.vue";
import SchedulerApiService from "@/core/services/api.service.scheduler";
import moment from "moment";

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },
  name: "scheduler",
  components: {
    SchedulerForm,
    SchedulerCreateDialog
  },
  data() {
    return {
      mounted: false,
      shops: [],
      selectedShop: undefined,
      shopsLoading: false,
      dataSource: [],
      filteredDataSource: [],
      dataSourceLoading: false,
      cities: [],
      selectedCity: undefined,
      selectedEventType: undefined,
      cityId: undefined,
      countryId: undefined,
      focus: moment().toISOString(),
      today: moment(),
      viewEnums: SchedulerViewType,
      viewType: SchedulerViewType.CALENDAR,
      selectedDate: undefined,
      dateFormat: "DD-MM-YYYY HH:mm",
      types: Object.keys(SchedulerMap),
      typeMap: SchedulerMap
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    await SchedulerApiService.init();

    if (this.$refs.calendar) {
      this.$refs.calendar.checkChange();
    }

    this.getSelectedCity();
    this.getShops();
    this.getSchedulerList();
    this.mounted = true;
  },
  methods: {
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    onToggleFormDialog(row) {
      const config = {
        data: row,
        cities: this.cities,
        selectedCity: this.selectedCity
      };

      this.$refs.schedulerFormDialog.open(config);
    },
    onCreateItemDialog() {
      this.$refs.schedulerCreateDialog.open();
    },
    getShops() {
      this.shopsLoading = true;
      SchedulerApiService.getShops()
        .then(res => {
          this.shops = res.data;
        })
        .finally(() => {
          this.shopsLoading = false;
        });
    },
    onRefreshList() {
      this.getSchedulerList();
    },
    getSchedulerList() {
      this.dataSourceLoading = true;
      const params = this.getParams();

      SchedulerApiService.getSchedulerList(params)
        .then(res => {
          this.dataSource = res.data;

          this.filterData();
        })
        .finally(() => {
          this.dataSourceLoading = false;
        });
    },
    getSelectedCity() {
      this.cityId = +this.$route.params.cityId;
      this.countryId = +this.$route.params.countryId;
      SchedulerApiService.getCities({
        countryId: this.countryId
      }).then(res => {
        this.cities = res.data;
        this.selectedCity = this.cities.find(c => c.id === this.cityId);
      });
    },
    onDayChange(event) {
      if (this.viewType === SchedulerViewType.CALENDAR) return;

      if (this.selectedDate === event.date) {
        this.resetSelectedDate();
      } else {
        this.selectedDate = event.date;
      }

      this.filterData();
    },
    onWeekChange() {
      if (!this.mounted) return;
      this.resetSelectedDate();
      this.getSchedulerList();
    },
    onShopChange() {
      this.getSchedulerList();
    },
    onEventTypeChange() {
      this.filterDataByEventType();
    },
    resetSelectedDate() {
      this.selectedDate = undefined;
    },
    filterDataByEventType() {
      this.dataSource.forEach(item => {
        item["hidden"] = this.selectedEventType && item.eventType !== this.selectedEventType;
      });
    },
    highlightSelectedDate(obj) {
      if (!obj.date) return;
      return obj.date === this.selectedDate;
    },
    getParams() {
      const params = {};

      if (this.cityId) {
        params["cityId"] = this.cityId;
      }

      if (this.selectedShop) {
        params["shopId"] = this.selectedShop;
      }

      const start = this.$refs.calendar.lastStart?.date;
      const end = this.$refs.calendar.lastEnd?.date;

      params["startDate"] = moment(start)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        })
        .unix();

      params["endDate"] = moment(end)
        .set({
          hour: 24,
          minute: 0,
          second: 0,
          millisecond: 0
        })
        .unix();

      return params;
    },
    filterData() {
      this.filterDataByEventType();

      if (this.selectedDate) {
        this.filteredDataSource = this.filterDataSourceForDate(this.selectedDate);
      } else {
        this.filteredDataSource = [...this.dataSource];
      }
    },
    formatUnixDate(num, format) {
      return moment(num * 1000).format(format || this.dateFormat);
    },
    formatDate(date, format) {
      return moment(date).format(format || this.dateFormat);
    },
    calcDuration(item) {
      return this.formatUnixDate(item.startDate + item.duration);
    },
    getShopNames(shops) {
      return shops.map(item => item.name).join(",");
    },
    filterDataSourceForDate(passedDate) {
      const startOfDay = moment(passedDate);

      return this.dataSource
        .filter(data => {
          const dateToMs = data.startDate * 1000;

          const date = moment(dateToMs);
          return date.isSame(startOfDay, "day");
        })
        .sort((prev, next) => {
          return prev.startDate - next.startDate;
        });
    },
    onPublishedChange(value) {
      const postModel = { ...value };

      SchedulerApiService.saveSchedule(postModel).then(() => {
        this.$bvToast.toast(this.$i18n.t("common.savedSuccessfully"), {
          title: this.$i18n.t("menu.scheduler"),
          solid: true
        });
      });
    },

    reloadData() {
      this.getSchedulerList();
    },
    sumDayPostCount(obj) {
      if (!obj) return;
      const startOfDay = moment(obj.date);

      return (
        this.dataSource.filter(item => {
          const dateToMs = item.startDate * 1000;
          return moment(dateToMs).isSame(startOfDay, "day");
        })?.length || 0
      );
    },
    sumDayPlannedCost(obj) {
      if (!obj) return;
      const startOfDay = moment(obj.date);

      return this.dataSource
        .filter(item => {
          const dateToMs = item.startDate * 1000;
          return moment(dateToMs).isSame(startOfDay, "day");
        })
        .reduce((prev, next) => {
          return prev + next.maxRevenue;
        }, 0);
    },
    onViewChange() {
      if (this.viewType === SchedulerViewType.TABLE) {
        this.viewType = SchedulerViewType.CALENDAR;
      } else {
        this.viewType = SchedulerViewType.TABLE;
      }
    },
    daysInCurrentWeek() {
      if (!this.$refs.calendar) return [];

      const start = this.$refs.calendar.$data.lastStart.date;
      const end = this.$refs.calendar.$data.lastEnd.date;
      let startDate = moment(start);
      const endDate = moment(end).add(1, "days");
      var dates = [];

      while (startDate.format("MM/DD/YYYY") !== endDate.format("MM/DD/YYYY")) {
        dates.push(startDate.toDate());
        startDate = startDate.add(1, "days");
      }

      return dates;
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", "currentUser"]),
    name() {
      return this.$i18n.t("menu.scheduler");
    },
    headers() {
      return [
        { text: "Id", align: "start", sortable: false },
        { text: this.$i18n.t("common.type"), align: "start", value: "type", sortable: false },
        { text: this.$i18n.t("common.shop"), align: "start", sortable: false },
        { text: this.$i18n.t("pages.scheduler.startDateAndTime"), align: "start", sortable: false },
        { text: this.$i18n.t("pages.scheduler.endDateAndTime"), align: "start", sortable: false },
        { text: this.$i18n.t("pages.scheduler.postCount"), align: "start", sortable: false },
        { text: this.$i18n.t("pages.scheduler.plannedCost"), align: "start", sortable: false },
        { text: this.$i18n.t("commonTable.published"), align: "start", sortable: false },
        // { text: 'Total Spent', align: 'start', sortable: false },
        { text: "", align: "start", sortable: false }
      ];
    },
    activeView() {
      return this.viewType;
    },
    title() {
      if (!this.$refs.calendar) return;

      return this.$refs.calendar.title;
    },
    startDate() {
      if (this.$refs.calendar) {
        return this.$refs.calendar.$data.lastStart.date;
      }

      return "";
    },
    endDate() {
      if (this.$refs.calendar) {
        return this.$refs.calendar.$data.lastEnd.date;
      }

      return "";
    },
    plannedCostTotal() {
      return this.filteredDataSource.reduce(function(prev, next) {
        return prev + next.maxRevenue;
      }, 0);
    }
  }
};
