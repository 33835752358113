const WindowRedirectService = {
  SIZE: 'width=700,height=900',
  REDIRECT_KEY: 'WINDOW_REDIRECTED',
  CLOSE_EVENT: 'onWindowCloseEvent',

  registerEvents(obj) {
    return {
      /**
       * Listen to OnClose event by passing a function
       * @param {*} fn
       */
      onClose: (fn, config) => {
        obj.addEventListener(this.CLOSE_EVENT, fn, config);
      },

      /**
       * Trigger onClose event and pass data to function
       * @param {*} data
       */
      triggerClose: data => {
        const event = new CustomEvent(this.CLOSE_EVENT, { detail: data });
        obj.dispatchEvent(event);
      },

      removeEvent: (name, fn, config) => {
        obj.removeEventListener(name, fn, config);
      }
    };
  },

  /**
   * Open new window, create events for it and get it's events
   * @param {*} href
   * @param {*} name
   * @param {*} size
   * @returns { window: Window, events: any }
   */
  openWindow(href, size) {
    const newWindow = window.open(href, '_blank', size || this.SIZE);
    newWindow[this.REDIRECT_KEY] = this.registerEvents(newWindow);
    return newWindow;
  },

  /**
   * Get window  redirect events
   * @param {*} obj
   * @returns
   */
  getEvents(obj) {
    return obj[this.REDIRECT_KEY];
  },

  /**
   * Check the new window is opened by current window
   * @param {*} obj
   * @returns
   */
  isWindowRedirected(obj) {
    if (obj) {
      return !!obj[this.REDIRECT_KEY];
    }

    return !!window[this.REDIRECT_KEY];
  }
};

export default WindowRedirectService;
